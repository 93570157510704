const dev = {
  API_ENDPOINT: "http://127.0.0.1:8000/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6Lf93dkZAAAAAOWiX3ZCteqvV_XdX3DMkYNrE3xJ",
  FRONT_URL: "http://localhost:3000/"
};

const stage = {
  API_ENDPOINT: "https://api.kitchen365test.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LewONodAAAAAH9ur7_b_pgF8S8mfiE1-iGb5Avt", //for staging key 6LeyuewZAAAAAPzVWBaWQtoX1cyuY1FpHwS1Czhd
  FRONT_URL: "https://2020pmsfront.cptestserver.com/"
};

const prod = {
  API_ENDPOINT: "https://api.kitchen365.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6Lfl0jIeAAAAAGWghl6uRMnxHIPMda8zpBUiA-DY", // 6LeJdCoaAAAAADqzSeExaipW4L_9Iz_6hldcQN1F
  FRONT_URL: "https://app.kitchen365.com/"
};

function getConfig() {
  if (process.env.NODE_ENV === "production") {
    return prod;
  } else {
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'staging') {
      return stage
    }
    return dev;
  }
}

const config = getConfig();

export default config;

